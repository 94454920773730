html,body{
    background-color: #FAFAFA;
    height: 100%;
}
body{
    overflow: visible;
}
#root{
    height: 100%;
    overflow: visible;
}
.container,.app,main{
    min-width: 980px !important;
}
.flex1{
    width: 10px;
    flex: 1;
}

.tip-update{
    float: right;
    font-size: .75rem;
}

option{
    padding: .2rem;
}
.process .info{
    font-size: 12px;
}
.panel{
    padding: 1.5rem !important;
    box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
    text-align: center;
    height: 12rem;
    background-color: white;
    margin-bottom: 1.5rem;
}
.panel .logo{
    width: 6em;
}
.panel .icon-logo{
    font-size: 5rem;
    line-height: 6rem;
    display: block;
    text-align: center;
    color: #2E9CEC;
}
.panel .title{
    font-size: 1.25rem;
    height: 3.375rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    line-height: 1.1;
}
.panel .text{
    font-size: 1rem;
    margin: 1.5rem 0 0 0;
}

.conatact{
    position: fixed;
    right: 0;
    top: 50%;
    text-decoration: none;
    color: white;
    text-align: center;
    padding: 1rem;
    margin-right: 1rem;
}
.conatact:hover,.conatact:visited{
    text-decoration: none;
    color: white;
}
span.conatact-icon{
    line-height: 3rem;
    font-size: 3rem;
}
.conatact-text{
    font-size: 1rem;
}

.tree-menu a{
    font-weight: bold;
    color: black;
}

.code{
    background-color: #F7F7F7;
    color: #666;
    white-space: pre;
    display: block;
}

.info-table{
    color: rgba(0, 0, 0, .65);
    font-size: .85rem;
}
.info-table thead{
    background-color: #F7F7F7;
}

.doc{
    padding-bottom: 1rem;
}
.doc h1,.doc h2,.doc h3,.doc h4,.doc h5,.doc h6{
    font-weight: bold;
}
.doc h3{
    margin-top: 2.5rem;
    margin-bottom: 1.5rem;
    font-size: 1.5rem;
}
.doc h5{
    margin-top: 1.5rem;
    margin-bottom: 1rem;
    font-size: 1rem;
}

.p{
    text-indent: 2em;
}

a.c{
    text-decoration: none;
    color: inherit;
}

.dialog-close-button{
    position: absolute;
    right: 0;
    top: 0;
    line-height: 3rem;
    width: 3rem;
    display: block;
    text-align: center;
    cursor: pointer;
    font-size: 1.6rem;
    z-index: 1;
}

.bg-transition{
    transition: background-color 1s;
}

.sticky-menu{
    position: fixed;
    width: 100%;
    z-index: 999;
}

.slash-label{
    position: absolute;
    right: 0;
    bottom: 0;
    height: 2rem;
    width: 2rem;
    font-size: .8rem;
    overflow: visible;
    background-color: transparent;
    z-index: 1;
    color: white;
    font-weight: bolder;
}

.slash-label::before{
    content: ' ';
    display: block;
    width: 300%;
    height: 300%;
    background-color: red;
    position: absolute;
    z-index: -1;
    margin-top: -50%;
    margin-left: -50%;
    transform: rotateZ(45deg);
}

.slash-label-color-1::before{
    background-color: #FF330E;
}
.slash-label-color-2::before{
    background-color: #FF6B00;
}
.slash-label-color-3::before{
    background-color: #FFB200;
}
.slash-label-color-4::before{
    background-color: #04CD65;
}
.slash-label-color-5::before{
    background-color: #00A3A2;
}
.slash-label-color-6::before{
    background-color: #006AE0;
}
.slash-label-color-7::before{
    background-color: #BE0DBF;
}


.circle{
    display: inline-block;
    text-align: center;
    width: 1.8em;
    height: 1.8em;
    border-radius: 50%;
    border: 1px solid white;
}

.red{
    color: red;
}

.info-table tbody {
    text-align: center;
}





@media screen and (max-width: 980px) {
    .conatact{
        top: auto;
        bottom: 1.5rem;
        padding: 1.5rem;
        margin-right: 1.52rem;
    }
    span.conatact-icon{
        line-height: 5rem;
        font-size: 5rem;
        vertical-align: middle;
    }
    .conatact-text{
        display: inline;
        font-size: 3rem;
        vertical-align: middle;
        margin: 0 .5rem;
    }
}
